import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class TimelineTwoController {
  constructor() {
    this.timeline = document.querySelector(".timeline-view--wrapper");
    this.viewTimelineBtn = document.querySelector(
      ".layout-region__container.our-mission-header a"
    );
    this.timelineContainer = document.querySelector(
      ".layout-region__container.our-mission-header .views-element-container"
    );
    this.timelineItems = document.querySelectorAll(".timeline__item");
    this.timelineHeader = document.querySelector(
      ".layout-region__container.our-mission-header .layout .layout__region"
    );
    this.counter = null;
    this.timelineHeights = {};
    this.positions = {};
    this.isScrollingInTimeline = false;
    this.accumulatedHeight = 0;
    this.lastVisitedIndex = -1;
    this.scrolledToView = false;

    this.setupScrollListeners();
    this.init();
    this.timelineContainer.classList.add("secondary");
    this.updateContainerHeight();
    this.toggleTimelineVisibility();
    this.moveOnClick();
  }

  setupScrollListeners() {
    this.timelineContainer.addEventListener("scroll", () => {
      this.isScrollingInTimeline = true;

      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.isScrollingInTimeline = false;
      }, 100);
    });
  }

  init() {
    this.setupScrollTriggers();
    this.viewTimelineBtn.addEventListener("click", (e) =>
      this.toggleTimelineVisibility(e)
    );
    window.addEventListener("resize", () => {
      this.setupScrollTriggers();
      this.calcItemsPosition();
      this.updateContainerHeight();
      this.scrolledToView = false;
    });
    this.calcItemsPosition();
    this.createCounter();
    this.updateContainerHeight();
  }

  moveOnClick() {
    const isMobile = window.innerWidth < 1024;

    this.timelineItems.forEach((item, index) => {
      item.addEventListener("click", () => {
        this.setCurrentItem(item, index);
        this.moveToItem(index);
      });
    });
  }

  moveToItem(index) {
    const isSmallMobile = window.innerWidth < 380;

    const position = this.positions[index];
    const nextElPosition =
      this.positions[this.timelineItems[index + 1]] || null;
    let topPosition =
      position + (nextElPosition ? nextElPosition / 2 - 50 : 200);

    this.timelineContainer.scrollTo({
      top: topPosition,
      behavior: "smooth",
    });
  }

  setupScrollTriggers() {
    this.resetTimeline();

    const containerHeight =
      this.timelineContainer.getBoundingClientRect().height;

    this.timelineItems.forEach((item, index) => {
      const isMobile = window.innerWidth < 1024;
      let start = `top-=25% top`;
      let end = `bottom bottom+=25%`;

      ScrollTrigger.create({
        trigger: item,
        scroller: this.timelineContainer,
        start: start,
        end: end,
        onEnter: () => {
          if (index > this.lastVisitedIndex) {
            this.setCurrentItem(item, index);
          }
        },
        onLeaveBack: () => this.handleLeaveBack(item, index),
      });
    });

    ScrollTrigger.create({
      trigger: this.timelineContainer,
      scroller: this.timelineContainer,
      start: "top top",
      end: `bottom+=${
        this.timelineContainer.getBoundingClientRect().height
      } bottom`,
      scrub: true,
      // markers: true,
      onUpdate: (self) => {
        const isMobile = window.innerWidth < 1024;
        const isSmallMobile = window.innerWidth < 380;
        if (this.isScrollingInTimeline) {
          if (isMobile && !this.scrolledToView) {
            this.scrolledToView = true;
            const timelineRect = this.timeline.getBoundingClientRect();
            const offset =
              window.scrollY + timelineRect.top - (isSmallMobile ? 100 : 200);
            window.scrollTo({
              top: offset,
              behavior: "smooth",
            });
          }
        }

        this.adjustTimelineTranslate(self);
      },
    });
  }

  adjustTimelineTranslate(scrollInfo) {
    const containerHeight =
      this.timelineContainer.getBoundingClientRect().height;
    const isMobile = window.innerWidth < 1024;
    const maxTranslateY = containerHeight / 2 + 150;
    const translateY = scrollInfo.progress * maxTranslateY;
    this.timeline.style.transform = `translateY(${translateY}px)`;
  }

  setCurrentItem(item, index) {
    this.clearCurrentItem();
    item.classList.add("active", "current");
    this.updateCounter(index);

    if (this.timelineHeights[index]) {
      this.timeline.style.setProperty(
        "--box-height",
        `${this.timelineHeights[index]}px`
      );
      this.lastVisitedIndex = index;
      this.accumulatedHeight = this.timelineHeights[index];
      return;
    }

    this.updateBoxHeight(item, index);
  }

  clearCurrentItem(item = null) {
    this.timelineItems.forEach((el) => el.classList.remove("current"));
    if (item) item.classList.remove("active");
  }

  handleLeaveBack(item, index) {
    this.clearCurrentItem(item);
    const previousItem = this.timelineItems[index - 1] || this.timelineItems[0];
    this.setCurrentItem(previousItem, index - 1);
  }

  calcItemsPosition() {
    let totalDistance = 0;
    const isMobile = window.innerWidth < 1024;
    const isSmallMobile = window.innerWidth < 380;
    this.timelineItems.forEach((item, index) => {
      let distance = 0;
      const offset = 80;
      const prevEl = this.timelineItems[index - 1] || null;

      if (prevEl) {
        distance += prevEl.getBoundingClientRect().height / 2;
        distance += offset;
        distance += item.getBoundingClientRect().height / 2;
        distance += totalDistance;
      }

      this.positions[index] = distance;
      totalDistance = distance;
    });

    this.timeline.style.setProperty(
      "--line-height",
      `${totalDistance + (isSmallMobile ? 40 : 0)}px`
    );
  }

  updateBoxHeight(currentItem, index) {
    const previousItem = this.timelineItems[index - 1] || null;

    if (!previousItem) {
      this.accumulatedHeight = 0;
      this.lastVisitedIndex = index;
      // this.scrolledToView = false;
      this.timelineHeights[index] = 0;
      return this.timeline.style.setProperty("--box-height", `0px`);
    }

    const additionalHeight = 80;
    const currentItemHalfHeight =
      currentItem.getBoundingClientRect().height / 2;
    const previousItemHeight = previousItem.getBoundingClientRect().height / 2;

    const newHeight =
      previousItemHeight + additionalHeight + currentItemHalfHeight;

    if (index > this.lastVisitedIndex) {
      this.accumulatedHeight += newHeight;
    } else if (index < this.lastVisitedIndex) {
      this.accumulatedHeight -= newHeight;
    }

    this.lastVisitedIndex = index;
    this.timeline.style.setProperty(
      "--box-height",
      `${this.accumulatedHeight}px`
    );
    if (this.timelineHeights.length === this.timelineItems.length) return;
    this.timelineHeights[index] = this.accumulatedHeight;
  }

  toggleTimelineVisibility() {
    const isVisible = this.timeline.classList.toggle("visible");
    this.timelineContainer.classList.toggle("visible");
    this.counter.classList.toggle("visible");
    this.viewTimelineBtn.textContent = isVisible
      ? "Hide timeline"
      : "View timeline";

    if (!isVisible) {
      this.resetTimeline();
    } else {
      setTimeout(() => {
        this.timelineContainer.scrollTo({ top: 1, behavior: "smooth" });
      }, 400);
    }
  }

  resetTimeline() {
    this.timelineItems.forEach((item) =>
      item.classList.remove("active", "current")
    );
    this.timelineContainer.scrollTo({ top: 1 });
    this.accumulatedHeight = 0;
    this.lastVisitedIndex = -1;
    this.timelineHeights = {};
    this.timeline.style.transform = `translateY(0px)`;

    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    ScrollTrigger.clearScrollMemory();
  }

  updateContainerHeight() {
    const containerHeight =
      this.timelineItems[0].getBoundingClientRect().height * 3 + 80 * 3;
    this.timelineContainer.style.setProperty(
      "--item-height",
      `${containerHeight}px`
    );
    return containerHeight;
  }

  createCounter() {
    this.counter = document.createElement("div");
    this.counter.classList.add("count", "absolute");
    this.counter.textContent = `(1/${this.timelineItems.length})`;
    this.timelineHeader.appendChild(this.counter);
  }

  updateCounter(index) {
    this.counter.textContent = `(${index + 1}/${this.timelineItems.length})`;
  }
}

export default TimelineTwoController;
