class RegionService {
  constructor() {
      this.baseUrl = 'https://ipapi.co/json';
      this.regionMenuItems = document.querySelectorAll('.region-container ul li');
      this.handleHomeUrl();
  }

  handleHomeUrl() {
    const selectedRegion = this.getCookie('selectedRegionByUser');
    let homeUrl = '/';
    if (selectedRegion) {
        this.regionMenuItems.forEach(item => {
            if (item.innerText === selectedRegion) {
                item.setAttribute('data-active', 'true');
                homeUrl = item.getAttribute('data-url');
            }
        })
    }
    const links = document.querySelectorAll('a');
    const homeLinks = Array.from(links).filter(link => link.getAttribute('href') === '/');
    homeLinks.forEach(link => {
        link.href = homeUrl;
    })

    const isHomepage = window.location.pathname === '/';

    if (isHomepage && homeUrl !== '/') {
        window.location = homeUrl;
    }
    
  }

  async getIpData() {
      try {
          const response = await fetch(this.baseUrl);
          return await response.json();
      } catch (error) {
          console.error(error);
      }
  }

  getLocalizationString() {
      const localization = this.getSavedLocation();
      this.adjustLocalizationClass(localization);
      
      if (localization === 'US' || localization === 'United States') return 'United States';
      if (localization === 'CA' || localization === 'Canada') return 'Canada';
      
      return 'International';
  }

  adjustLocalizationClass(currentLocalization) {
      document.body.classList.forEach(currentClass => {
          if (currentClass.startsWith('country-')) {
              document.body.classList.remove(currentClass);
          }
      });

      if (currentLocalization === 'United States' || currentLocalization === 'US') {
          document.body.classList.add('country-us');
          return;
      }

      if (currentLocalization === 'Canada' || currentLocalization === 'CA') {
          document.body.classList.add('country-ca');
          return;
      }

      document.body.classList.add('country-international');
  }

  changeRegion(region) {
      this.adjustLocalizationClass(region);

      let regionCode = 'INTERNATIONAL';
      if (region === 'United States') {
          regionCode = 'US';
      } else if (region === 'Canada') {
          regionCode = 'CA';
      }

      this.setCookie('localization', regionCode, 30);  // Define expiração de 30 dias
      localStorage.setItem('localization', regionCode);
  }

  getSavedLocation() {
      const selectedRegion = this.getCookie('selectedRegionByUser');
      if (selectedRegion) {
          return selectedRegion;
      }
      
      const localization = this.getCookie('localization');
      if (localization) {
          return localization;
      }

      return 'US';
  }

  setCookie(name, value, days) {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
  }

  getCookie(name) {
      const cookies = document.cookie.split(';');
      for (const cookie of cookies) {
          const [key, value] = cookie.trim().split('=');
          if (key === name) {
              return value;
          }
      }
      return null;
  }
}

export default RegionService;
