import { init } from "aos"
import Modal from "./modal.class"

class LeadershipModal {
  constructor() {
    this.leadershipModal = null
    this.leadershipItems = document.querySelectorAll('.leadership-modal__wrapper .views-view-responsive-grid__item')
    this.activeModalData = {}
    this.init()
  }

  init() {
    this.createModal()
  }

  createModal() {
    const modal = document.createElement('div')
    modal.classList.add('leadership-modal')
    modal.innerHTML = `
        <div class="leadership-modal__wrapper">
            <div class="leadership-modal__close-wrapper">
                <button class="leadership-modal__close">
                    <img src="/themes/custom/envivent/assets/images/global/close-btn.svg" alt="Close">
                </button>
            </div>
            <div class="leadership-modal__content">
            </div>
        </div>
    `
    document.body.appendChild(modal)
    this.leadershipModal = modal
    this.leadershipModal.querySelector('.leadership-modal__close').addEventListener('click', () => this.closeModal())
    this.toggleEvents()
  }

  toggleEvents() {
    this.leadershipItems.forEach(item => {
      item.addEventListener('click', () => {
        this.activeModalData = {
          name: item.querySelector('.views-field-info').textContent,
          role: item.querySelector('.views-field-field-role').textContent || 'Member of the Board of Directors',
          bio: item.querySelector('.views-field-body .field-content').outerHTML,
          image: item.querySelector('.views-field-field-picture img').src,
          linkedin: item.querySelector('.views-field-field-linkedin-url a') ? item.querySelector('.views-field-field-linkedin-url a').href : ''
        }

        this.openModal()
      })
    })

    document.querySelector('.leadership-modal').addEventListener('click', (e) => {
      if (e.target.classList.contains('leadership-modal')) {
        this.closeModal()
      }
    })
  }

  openModal() {
    const modalContent = this.leadershipModal.querySelector('.leadership-modal__content')
    const content = `
        <div class="leadership-modal__content__wrapper">
            <div class="leadership-modal__content__wrapper__image">
                <img src="${this.activeModalData.image}" alt="${this.activeModalData.name}">
            </div>

            <div class="leadership-modal__content__wrapper__info">
                <h4 class="name">${this.activeModalData.name}</h4>
                <p class="role">${this.activeModalData.role}</p>
                ${this.activeModalData.linkedin !== '' ? `
                <a class="linkedin" href="${this.activeModalData.linkedin}" target="_blank" rel="noopener noreferrer">
                    <img src="/themes/custom/envivent/assets/images/global/linkedin-icon.svg" alt="LinkedIn">
                </a>` : ''}
                <div class="bio">
                    ${this.activeModalData.bio}
                </div>
            </div>
    `
    modalContent.innerHTML = content
    this.leadershipModal.classList.add('active')
    new Modal()
    document.documentElement.style.overflow = 'hidden'
  }

  closeModal() {
    const modalContent = this.leadershipModal.querySelector('.leadership-modal__content')
    this.leadershipModal.classList.remove('active')
    modalContent.innerHTML = ''
    document.documentElement.style.overflow = 'auto'
  }
  
}

export default LeadershipModal