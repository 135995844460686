import $ from "jquery";
import "magnific-popup";
import "./classes/polyfills.class.js";
import AnchorScroller from "./classes/anchor-scroller";
import AOS from "aos";
import CollapsibleItem from "./classes/collapsible-item.class.js";
import TabItem from "./classes/tab-item.class.js";
import FixedHeader from "./classes/fixed-header.class";
import FixedIsi from "./classes/fixed-isi.js";
import FormBehavior from "./classes/form-behavior.class.js";
import MainMenu from "./classes/main-menu.class.js";
import Carousel from "./classes/carousel.class.js";
import Modal from "./classes/modal.class.js";
import Violator from "./classes/violator.class";
import TimelineController from "./classes/timeline.class.js";
import LeadershipModal from "./classes/leadership.class.js";
import RegionService from "./classes/ip-service.class.js";
import gsap from "gsap";
import Splitting from "splitting";
import TimelineTwoController from "./classes/timeline-two.class.js";
import TimelineThreeController from "./classes/timeline-three.class.js";

// Initialize Animate on Scroll
AOS.init({
  duration: 1200,
});

// Initialize AnchorScroller
const scroll = new AnchorScroller(
  $("header[role=banner]").height(),
  $("header[role=banner]").height(),
  768
);
scroll.init();

$(window)
  .on("resize", function () {
    if ($("html").hasClass("enable_sticky_desktop")) {
      scroll.desktopOffset = $("header[role=banner]").height();
    }

    if ($("html").hasClass("enable_sticky_desktop")) {
      scroll.mobileOffset = $("header[role=banner]").height();
    }
  })
  .trigger("resize");

// Initialize Modal Class
new Modal();

$(() => {
  window.addEventListener("scroll", function () {
    const parallaxElement = document.querySelector(".block--type--hero-banner");

    if (parallaxElement) {
      const scrollPosition = window.scrollY;
      const rotation = scrollPosition * 0.02 * -1;
      const scale = 1 + scrollPosition * 0.0001;
      const topOffset = scrollPosition * 0.25;
      parallaxElement.style.setProperty("--scroll-rotate", rotation + "deg");
      parallaxElement.style.setProperty("--scroll-scale", scale);
      parallaxElement.style.setProperty("--scroll-top", topOffset + "px");
    }
  });

  Splitting();
  // GSAP animations
  // Home
  const hero = document.querySelector(".block--type--hero-banner");
  if (hero) {
    const heroh3 = hero.querySelector("h3");
    const results = Splitting({ target: heroh3, by: "words" });
    results.forEach((result) => {
      const words = result.words;
      words.forEach((word, index) => {
        gsap.from(word, {
          duration: 1,
          opacity: 0,
          y: 20,
          delay: index * 0.05,
        });
      });
    });
    gsap.from(hero.querySelector(".button"), {
      duration: 1,
      opacity: 0,
      y: 20,
      delay: 0.5,
    });
  }

  // Region Menu
  const menuRegion = document.querySelector(".menu-region");
  const regionMenuToggle = document.querySelector(".region-container button");
  const regionMenuList = document.querySelector(".region-container ul");
  const regionMenuListItems = document.querySelectorAll(
    ".region-container ul li"
  );
  const regionService = new RegionService();

  menuRegion.addEventListener("click", () => {
    regionMenuList.classList.toggle("visible");
  });

  regionMenuListItems.forEach((item) => {
    item.addEventListener("click", () => {
      regionMenuToggle.innerText = item.innerText;
      document.cookie = `selectedRegionByUser=${item.innerText}; path=/;`;
      regionMenuList.classList.remove("visible");
      regionService.changeRegion(item.innerText);

      setTimeout(() => {
        window.location.href = item.getAttribute("data-url");
      }, 300);
    });
  });

  const localizationString = regionService.getLocalizationString();
  regionMenuToggle.innerText = localizationString;

  // Prevent scroll to modal after click
  $("a").each(function () {
    const href = $(this).attr("href");

    if (
      typeof href !== "undefined" &&
      href.startsWith("#") &&
      href !== "#" &&
      $(href).hasClass("block--type--modal")
    ) {
      $(this).on("click", (e) => e.stopImmediatePropagation());
    }
  });

  const isi = document.querySelector(".isi .fixed");
  if (isi) {
    new FixedIsi();
  }

  const customPageFixedIsi = document.querySelector(".float-isi");
  const static_isi = document.querySelector(".isi__section");

  if (customPageFixedIsi) {
    const fixedIsi = new FixedIsi(customPageFixedIsi, static_isi);

    const isiTrigger = document.querySelector(".isi-expand-trigger");
    isiTrigger.addEventListener("click", () => {
      window.scrollTo({ top: static_isi.offsetTop - 20, behavior: "smooth" });
    });
  }

  const violator = document.querySelector(".violator");
  const topBarWrapper = violator.querySelector(".top-bar__wrapper");
  if (violator && !topBarWrapper) {
    new Violator(violator);
  }

  if (topBarWrapper) violator.classList.add("is-topbar");

  new FixedHeader();

  const mainMenu = document.querySelector(".layout-container > header");
  if (mainMenu) {
    new MainMenu(mainMenu);
  }

  const tabItems = document.querySelectorAll(".tab-items");
  if (tabItems.length) {
    tabItems.forEach((item) => {
      new TabItem(item);
    });
  }

  // Don't active slideshows on the Layout Builder page
  if (
    $(".block--type--carousel-slide").length &&
    !$(".node-page-layout-builder-form").length
  ) {
    $.uniqueSort($(".block--type--carousel-slide").parents(".layout__region"))
      .css({
        "min-width": 0,
      })
      .each(function () {
        new Carousel(this);
      });
  }

  document.querySelectorAll(".webform-submission-form").forEach((f) => {
    new FormBehavior(f);
  });

  document
    .querySelectorAll(".block--type--collapsible-item")
    .forEach((item) => {
      new CollapsibleItem(item);
    });

  if ($(".popup-youtube").length) {
    $(".popup-youtube").magnificPopup({
      type: "iframe",
      iframe: {
        patterns: {
          youtube: {
            index: "youtube.com/",
            id: "v=",
            src: "//www.youtube.com/embed/%id%?autoplay=1&modestbranding=1",
          },
        },
      },
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  }

  // Products Pearl Matrix
  const isProductPearlMatrix = window.location.pathname.includes(
    "/products/pearlmatrix"
  );
  if (isProductPearlMatrix) {
    const pauseBtn = document.querySelector(".play-btn");
    const text = pauseBtn.querySelector("span");
    const img = pauseBtn.querySelector(".video__wrapper img");
    const video = document.querySelector(".video__wrapper video");
    console.log(video);
    if (pauseBtn) {
      pauseBtn.addEventListener("click", () => {
        const isPlaying = !video.paused;
        if (isPlaying) {
          video.pause();
          text.innerText = "Play";
          img.src =
            "/themes/custom/envivent/assets/images/global/play-icon-2.svg";
        } else {
          video.play();
          text.innerText = "Pause";
          img.src =
            "/themes/custom/envivent/assets/images/global/pause-icon.svg";
        }
      });

      // finish video

      video.addEventListener("ended", () => {
        text.innerText = "Play again";
        img.src =
          "/themes/custom/envivent/assets/images/global/play-icon-2.svg";
      });
    }
  }

  // Evidence
  const isEvidencePage = window.location.pathname.includes("/science/evidence");
  if (isEvidencePage) {
    // View more button

    const blocksEl = document.querySelectorAll(
      ".layout-region__container:nth-of-type(3) .block--type--basic"
    );

    blocksEl.forEach((block) => {
      const btn = block.querySelector("a");
      const hidden = block.querySelector("p:nth-of-type(2)");
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        hidden.classList.toggle("visible");

        btn.textContent = hidden.classList.contains("visible")
          ? "View less"
          : "View more";
      });
    });

    // Tabs Adjustments
    // const tabOptionsWrapper = document.querySelector('.layout-region__container.tab-header')
    // const tabOptions = document.querySelectorAll('.layout-region__container.tab-header li')
    const firstTab = document.querySelector(
      ".layout-region__container.pearl-matrix-tab"
    );
    const secondTab = document.querySelector(
      ".layout-region__container.i-factor-tab"
    );
    const thirdTab = document.querySelector(
      ".layout-region__container.p-15-peptide-tab"
    );

    // const buttonTemplate = [...tabOptions].map((option, index) => {
    //   return `
    //     <button class="tab-button" data-index="${index}">
    //       ${option.outerHTML}
    //     </button>
    //   `
    // })
    // // tabOptionsWrapper.classList.add('tab-items')
    // // const tabsTemplate = `
    // //   <div class="tab-header">
    // //      ${buttonTemplate.join('')}
    // //   </div>
    // //   <div>
    // //     <div class="tab-item item-1">
    // //       <div class="tab-content active">
    // //         ${firstTab.outerHTML}
    // //       </div>
    // //     </div>
    // //     <div class="tab-item item-2">
    // //       <div class="tab-content">
    // //         ${secondTab.outerHTML}
    // //       </div>
    // //     </div>
    // //     <div class="tab-item item-3">
    // //       <div class="tab-content">
    // //         ${thirdTab.outerHTML}
    // //       </div>
    // //     </div>
    // //   </div>
    // // `
    // // tabOptionsWrapper.innerHTML = ''
    // // tabOptionsWrapper.innerHTML = tabsTemplate

    // new TabItem(tabOptionsWrapper)
    document
      .querySelectorAll(".block--type--collapsible-item")
      .forEach((item) => {
        item.classList.add("opened");
        new CollapsibleItem(item);
        item.addEventListener("click", () => {
          item.classList.toggle("opened");
        });
        // new CollapsibleItem(item).open()
      });

    // document.querySelector('.tab-header:nth-of-type(2)').remove()
    // firstTab.remove()
    // secondTab.remove()
    // thirdTab.remove()
  }

  const isWhoWeArePage = window.location.pathname === "/about-us/who-we-are";
  if (isWhoWeArePage) {
    // const timeline = new TimelineController()
    const timelineTwo = new TimelineTwoController();
    // const timelineTwo = new TimelineThreeController()
    const leadershipModal = new LeadershipModal();
  }

  const isNewsroomPage = window.location.pathname === "/news-and-events/news";
  if (isNewsroomPage) {
    const select = document.querySelector(
      "select#edit-field-publication-date-value"
    );
    const options = document.querySelectorAll(
      "select#edit-field-publication-date-value option"
    );
    const selectLabel = document.querySelector(
      'label[for="edit-field-publication-date-value"]'
    );
    const form = document.querySelector(
      "#views-exposed-form-news-view-block-1"
    );
    options[0].textContent = selectLabel.textContent;

    select.addEventListener("change", (e) => form.submit());
  }

  const isEventsPage = window.location.pathname === "/news-and-events/events";
  if (isEventsPage) {
    const events = document.querySelectorAll('.event-view-row');
    const groupedEvents = Array.from(events).reduce((acc, event) => {
        const dateElement = event.querySelector('.views-field-field-date');
        if (!dateElement) return acc;
        
        const dateText = dateElement.textContent.trim();
        const eventDate = new Date(dateText);
        
        if (isNaN(eventDate)) return acc;
        
        const monthKey = `${dateText.split(' ')[0]}`;
        
        if (!acc[monthKey]) {
            acc[monthKey] = [];
        }
        
        acc[monthKey].push(event);
        
        return acc;
    }, {});


    const viewContainer = document.querySelector('.event-view-column');
    const container = document.createElement('div');
    container.classList.add('accordion-container');
    Object.keys(groupedEvents).forEach(month => {
      const section = document.createElement('div');
      section.classList.add('accordion-section');
      section.classList.add(`${month.toLowerCase()}`);
      
      const button = document.createElement('button');
      button.textContent = month;
      button.classList.add('accordion-button');
      button.onclick = () => {
          const content = section.querySelector('.accordion-content');
          content.classList.toggle('hidden');
      };
      
      const content = document.createElement('div');
      content.classList.add('accordion-content');
      groupedEvents[month].forEach(event => {
        const dayToBeDefined = event.querySelector('.views-field-field-day-to-be-defined').textContent.trim() === 'True' ? true : false;
        console.log(dayToBeDefined)
        if (dayToBeDefined) {
          const dateTextEl = event.querySelector('.views-field-field-date .field-content');
          const dateText = dateTextEl.textContent.trim();
          const eventDate = new Date(dateText);
          const formatedDate = `${month}, ${eventDate.getFullYear()}`;
          console.log(formatedDate)

          dateTextEl.textContent = formatedDate;
        }


        content.appendChild(event);
      });
      
      section.appendChild(button);
      section.appendChild(content);
      container.appendChild(section);
    });

    viewContainer.appendChild(container);
  }

  const externalProductCaroulsel = document.querySelectorAll(
    ".external-product__carousel"
  );

  if (externalProductCaroulsel.length) {
    externalProductCaroulsel.forEach((carousel) => {
      const items = carousel.querySelectorAll(".carousel__item");
      const carouselInner = carousel.querySelector(".carousel__inner");
      if (items.length > 1) {
        const externalProductC = $(carouselInner).slick({
          autoplay: true,
          autoplaySpeed: 5000,
          pauseOnHover: true,
          arrows: false,
          dots: true,
          speed: 1000,
          mobileFirst: true,
        });
      }
    });
  }

  // Form
  if (window.location.pathname === "/contact-us") {
    hubspot.form.api.onFormReady((form) => {
      const hubspotForm = document.querySelector(
        ".hubspot-form__wrapper iframe"
      );
      if (hubspotForm) {
        const title = hubspotForm.contentDocument.querySelector(".hs-richtext");
        const radioInput = hubspotForm.contentDocument.querySelector(
          '.hs-input[type="radio"]'
        );
        const cloneCheckbox = radioInput.cloneNode();
        cloneCheckbox.type = "checkbox";
        radioInput.parentNode.insertBefore(
          cloneCheckbox,
          radioInput.parentNode.firstChild
        );

        radioInput.remove();

        title.style.display = "none";
        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        const styleContent = `
          .hs_reason_for_inquiry > label {
            margin: 10px 0;
          }

          input[type="submit"] {
            background-color: #FF8200 !important;
            border-radius: 15px !important;
            border: 2px solid #FF8200 !important;
            color: #101820 !important;
            cursor: pointer !important;
            display: flex !important;
            letter-spacing: 0.5px !important;
            font-size: 18px !important;
            font-weight: 600 !important;
            line-height: 27px !important;
            padding: 12px 15px !important;
            text-decoration: none!important;
            transition: background 0.2s, color 0.2s !important;
            width: fit-content !important;
            justify-content: center !important;
            align-items: center !important;
            border: 2px solid transparent !important;
            position: relative !important;
            transition: all 0.5s, ease !important;
            margin-top: 0 !important;
            margin-bottom: 20px !important;
          }

          input[type="submit"]::before {
            content: "";
              width: 100%;
              height: 100%;
              inset: 0;
              position: absolute;
              background-color:transparent;
              transition: all 1s ease;
              border-radius: 15px;
          }

          input[type="submit"]:hover::before {
            box-shadow: 0 0 15px 5px #FF8200 !important;
          }

          .hs-custom-style>div>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]), .hs-custom-style fieldset>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]) {
            background: white !important;
            border: 1px solid #000 !important;
            border-radius: 4px !important;
            padding: 9px 12px !important;
          }
        `;

        styleSheet.innerText = styleContent;
        hubspotForm.contentDocument.head.appendChild(styleSheet);
      }
    });
  }

  // Adjust for tab fixed item scroll

  const blocks = document.querySelectorAll(".block--type--basic");
  blocks.forEach((block) => {
    if (block.id.includes("uuid")) return;

    if (block.id) {
      const closestLayoutTegionContainer = block.closest(
        ".layout-region__container"
      );
      if (closestLayoutTegionContainer) {
        closestLayoutTegionContainer.id = block.id;
        block.id = "";
      }
    }
  });
});
