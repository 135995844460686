import "magnific-popup";
import $ from "jquery";

// options at: https://dimsemenov.com/plugins/magnific-popup/

class Modal {
  constructor() {
    this.isOppeningLink = false;
    if (
      document.location.hash !== "" &&
      $(document.location.hash).length &&
      $(document.location.hash).hasClass("block--type--modal")
    ) {
      $.magnificPopup.open({
        items: {
          src: document.location.hash,
          type: "inline",
        },
        overflowY: "scroll",
      });
    }

    $("a").each(function () {
      const href = $(this).attr("href");

      if ($(this).hasClass('no-popup') || $(this).parents().hasClass('no-popup')) {
        $(this).attr('target', '_blank');
        return;
      }

      if (
        typeof href !== "undefined" &&
        href.startsWith("#") &&
        href.length > 1 &&
        $(href).hasClass("block--type--modal")
      ) {
        console.log(href);

        $(this).magnificPopup({
          items: {
            src: href,
            type: "inline",
          },
          //type: 'inline',
          overflowY: "scroll",
        });
      }

      if (
        typeof href !== "undefined" &&
        !href.includes("cerapedics.com") &&
        href.startsWith("http") &&
        $("#popleave").length
      ) {
        $(this).magnificPopup({
          items: {
            src: "#popleave",
            type: "inline",
          },
          callbacks: {
            open: function () {
              const closeBtn = document.querySelector(".mfp-close");
              const okBtn = document.querySelector("#popleave .ok");
              okBtn.setAttribute("data-link", href);

              closeBtn.addEventListener("click", function (e) {
                e.preventDefault;
                e.stopPropagation;
                $.magnificPopup.close();
                this.isOppeningLink = false
                return false;
              });

              $("#popleave .ok").on("click", function (e) {
                if (this.isOppeningLink) return
                this.isOppeningLink = true;

                e.preventDefault();
                e.stopPropagation();
                window.open(e.target.getAttribute("data-link"), "_blank");
                $.magnificPopup.close();

                setTimeout(()=> {
                  this.isOppeningLink = false
                }, 500)
                return;
              });

              $("#popleave .cancel").on("click", function (e) {
                e.preventDefault;
                e.stopPropagation;
                $.magnificPopup.close();
                this.isOppeningLink = false
                return false;
              });
            },
          },
        });
      }
    });
  }
}

export default Modal;
