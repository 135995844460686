class TabItem {
  constructor(el) {
    this.tabWrapper = el;
    this.tabItems = el.querySelectorAll(".tab-item");
    this.contents = el.querySelectorAll(".tab-content");
    this.activeTab = 0;
    this.buttons = null;
    this.init();
  }

  createTabs() {
    const buttons = this.tabWrapper.querySelectorAll(".tab-button");
    buttons.forEach((button, index) => {
      button.setAttribute("data-index", index);
      button.classList.add("tab-button");
      button.classList.remove("hidden");

      if (index === this.activeTab) {
        button.classList.add("active");
        this.toggleContent(index);
      }
    });
    const template = `
            <div class="tab-header">
                ${Array.from(buttons)
                  .map((button) => button.outerHTML)
                  .join("")}
            </div>
        `;
    buttons.forEach((button) => button.remove());
    this.tabWrapper.insertAdjacentHTML("afterbegin", template);
    this.handleEvents();
    // this.setTableContentHeight()
  }

  setTableContentHeight() {
    let currentActive;
    [...this.contents].map((content) => {
      if (content.classList.contains("active")) {
        currentActive = content;
      }
    });

    const heights = [];
    this.contents.forEach((content, index) => {
      content.classList.add("active");
      heights.push(content.clientHeight);

      if (content !== currentActive) {
        content.classList.remove("active");
      }
    });

    const maxHeight = Math.max(...heights);
    const screenWidth = window.innerWidth;
    if (screenWidth > 1024) {
      this.contents.forEach((content) => {
        content.style.height = `${maxHeight}px`;
      });
    } else {
      this.contents.forEach((content) => {
        content.style.height = "auto";
      });
    }
  }

  handleEvents() {
    this.buttons = document.querySelectorAll(".tab-header .tab-button");
    this.buttons.forEach((button) => {
      button.addEventListener("click", () => {
        this.activeTab = button.getAttribute("data-index");
        this.toggleTab(button, this.activeTab);
      });
    });

    // window.addEventListener('resize', () => {
    //     this.setTableContentHeight()
    // })
  }

  toggleTab(button, index) {
    this.buttons.forEach((button) => {
      button.classList.remove("active");
    });
    button.classList.add("active");
    this.toggleContent(index);
  }

  toggleContent(index) {
    this.contents.forEach((content) => {
      content.classList.remove("active");
    });
    this.contents[index].classList.add("active");
  }

  init() {
    console.log("TabItem initialized");
    this.createTabs();
  }
}

export default TabItem;
